export const headerStyle = {
  fontFamily: "'Teko', sans-serif",
  fontStyle: "normal",
  fontWeight: "600",
  fontSize: "1.5rem",
  lineHeight: "34px",
  paddingRigh: "0"
};

export const cardStyle = {
  background: "none",
  border: "none",
  display: "flex",
  justifyContent: "center",
  height: "auto",
  marginTop: "140px",
  boxShadow: "none",
};

export const cardContentStyle = {
  boxShadow: "none",
  border: "1px solid rgba(255, 255, 255, .2)",
  backgroundColor: "rgba(255, 255, 255, 0.1)",
  display: "flex",
  flexDirection: "column",
  gap: "25px",
  alignItems: "center",
  padding: "24px 24px 46px;",
};

export const cardProgressStyle = {
  ...cardContentStyle,
  flexDirection: "row",
  justifyContent: "space-between",
  padding: "10px 11px 11px",
  "&:last-child": {
    paddingBottom: "11px",
  },
};

export const progressBarContainer = {
  boxShadow: "none",
  border: "1px solid rgba(255, 255, 255, .2)",
  backgroundColor: "rgba(255, 255, 255, 0.1)",
  alignItems: "center",
  margin: "0",
  marginBottom: "4px",
  padding: "1px",
};
