import { ContentContainer } from "./Content.styles";
import Connect from "../connect/Connect";
import VestingFetch from "../vesting/VestingFetch";
import { useAccount } from "wagmi";

const Content = () => {
  const { isConnected } = useAccount();

  return (
    <div>
      {isConnected ? (
        // <ContentContainer>
        // </ContentContainer>
        <VestingFetch />
      ) : (
        <Connect />
        // <ContentContainer>
        // </ContentContainer>
      )}
    </div>
  );
};

export default Content;
