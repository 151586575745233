import styled from "styled-components";

export const InfoContainer = styled.div`
  width: 72px;
  height: 88px;
  margin: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.5);
  clip-path: polygon(100% 0, 100% 80%, 80% 100%, 0 100%, 0 0);
`;
