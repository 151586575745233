import { Box, CircularProgress, Container, Grid } from "@mui/material";
import symbol from "../../assets/icons/Vector.jpeg";

import {
  ParsedClaimRoundsProps,
  StringifiedRoundProps,
} from "../claim/claim.types";
import { useAccount, useContractRead } from "wagmi";
import ABI from "../../config/abi.json";
import { useEffect, useState } from "react";
import { VestingContentContainer } from "../vesting/Vesting.styles";
import ButtonDropp from "../button/Button";
import { H1, H4, H5 } from "../typefaces/typefaces.styles";
import VestingSchedule from "../vesting/vestingSchedule/VestingSchedule";
import OverviewCard from "../overviewCard/OverviewCard";
import { toLowerCurrencyUnit } from "../../config/utils";
import InitVestingButton from "../initVestingButton/InitVestingButton";
import ClaimButton from "../claimButton/ClaimButton";
// import { BigNumber, ethers } from "ethers";

type Props = {
  round: ParsedClaimRoundsProps;
  irlPrice: number;
};

const ClaimRound = ({ round, irlPrice }: Props) => {
  const { address } = useAccount();
  const [totalAmounts, setTotalAmounts] = useState(0);
  const [unlockedBalance, setUnlockedBalance] = useState(0);
  const [isButtonReady, setIsButtonReady] = useState(false);
  const [isClaimedReady, setIsClaimedReady] = useState(false);
  const [isStringifiedReady, setIsStringifiedReady] = useState(false);
  const [isInitButton, setIsInitButton] = useState(false);
  const [isClaimButton, setIsClaimButton] = useState(false);
  const [roundStrigified, setRoundStrigified] = useState<any>();

  const { data: isClaimed, isSuccess: isClaimedRead } = useContractRead({
    address: "0x4151258Fb3D2a514b5cec91eF1D894023d79B6bd",
    abi: ABI,
    chainId: 56,
    functionName: "isClaimed",
    watch: true,
    args: [round.roundNumber, round.data.indexes[0]],
  });

  const { data: getClaimable, isSuccess: isClaimableRead } = useContractRead({
    address: "0x4151258Fb3D2a514b5cec91eF1D894023d79B6bd",
    abi: ABI,
    chainId: 56,
    functionName: "getClaimable",
    watch: true,
    args: [round.roundNumber, address],
  });

  const { data: claimed, isSuccess: isClaimedDataRead } = useContractRead({
    address: "0x4151258Fb3D2a514b5cec91eF1D894023d79B6bd",
    abi: ABI,
    chainId: 56,
    functionName: "claimed",
    watch: true,
    args: [round.roundNumber, address],
  });

  // totalValue & unlocked balance
  useEffect(() => {
    let totalAmounts = round.data.amounts.reduce(function (a, b) {
      return a + b;
    });
    setTotalAmounts(totalAmounts);

    const currentTime = new Date().getTime() / 1000;

    if (currentTime > round.startTime && !isClaimed) {
      const newUnlockedBalance = totalAmounts * 0.15;
      setUnlockedBalance(newUnlockedBalance);
    }
  }, [round, isClaimed]);

  // stringify ammount
  useEffect(() => {
    const parseRound = async () => {
      let parsedRound: StringifiedRoundProps = {
        roundNumber: round.roundNumber,
        data: {
          ...round.data,
          amounts: round.data.amounts.map((amount) =>
            amount.toLocaleString("fullwide", { useGrouping: false })
          ),
        },
      };

      // console.log("parsedRound");
      // console.log(parsedRound);

      setRoundStrigified(parsedRound);
      setIsStringifiedReady(true);
    };

    parseRound();
  }, [round]);

  useEffect(() => {
    if (isClaimedDataRead && claimed !== undefined) {
      setIsClaimedReady(true);
    }
  }, [claimed, isClaimedDataRead]);

  // Decide which button to show
  useEffect(() => {
    if (isClaimedRead) {
      isClaimed ? setIsInitButton(false) : setIsInitButton(true);
    }
    if (isClaimableRead) {
      getClaimable ? setIsClaimButton(true) : setIsClaimButton(false);
    }
    if (isClaimedRead && isClaimableRead) {
      setIsButtonReady(true);

      if (isClaimed) {
        setUnlockedBalance(Number(getClaimable));
      }
    }
  }, [isClaimed, isClaimedRead, isClaimableRead, getClaimable]);

  // Callback to refresh component after transaction
  const handleInitButtonClick = () => {
    setIsInitButton(false);
    setIsClaimButton(false);
    setIsButtonReady(false);
  };

  const handleClaimButtonClick = () => {
    setIsInitButton(false);
    setIsClaimButton(false);
    setIsButtonReady(false);
    setIsClaimedReady(false);
  };

  return (
    <>
      {
        <Container>
          <VestingContentContainer>
            {/* <Grid container spacing={2.5}>
              <Grid item xs={4}>
                <LabelH5>Total Value Locked</LabelH5>
                {<H1>$ {totalValueLocked.toFixed(2)}</H1>}
              </Grid>
              <Grid item xs={4}>
                <LabelH5>IRL Price</LabelH5>
                {<H1>$ {irlPrice}</H1>}
              </Grid>
            </Grid> */}
            <div>
              <H4>Account Overview</H4>
              <Grid container spacing={2.5}>
                <Grid item xs={4}>
                  {isClaimedReady ? (
                    <OverviewCard
                      data={{
                        title: "Total Vested",
                        value: toLowerCurrencyUnit(totalAmounts).toFixed(2),
                        currency: "IRL",
                      }}
                    />
                  ) : (
                    <CircularProgress color="secondary" />
                  )}
                </Grid>
                <Grid item xs={4}>
                  {isClaimedReady ? (
                    <OverviewCard
                      data={{
                        title: "Locked Balance",
                        value: toLowerCurrencyUnit(
                          totalAmounts - Number(claimed) - Number(getClaimable)
                        ),
                        currency: "IRL",
                      }}
                    />
                  ) : (
                    <CircularProgress color="secondary" />
                  )}
                </Grid>
                <Grid item xs={4}>
                  {isClaimedReady ? (
                    <OverviewCard
                      data={{
                        title: "Unlocked Balance",
                        value: toLowerCurrencyUnit(unlockedBalance),
                        currency: "IRL",
                      }}
                    />
                  ) : (
                    <CircularProgress color="secondary" />
                  )}
                </Grid>
              </Grid>
            </div>
            <VestingSchedule
              startTime={round.startTime}
              endTime={round.endTime}
            />
            <div>
              <Grid
                container
                direction="row-reverse"
                sx={{ marginTop: "0.125rem" }}
                spacing={2.5}
              >
                <Grid item xs={4}>
                  <div style={{ padding: "0 1rem" }}>
                    <H5>Available to Claim</H5>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        flexDirection: "column",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginTop: "0.5rem",
                        }}
                      >
                        <Box>
                          <img
                            style={{
                              border: "none",
                              margin: 0,
                            }}
                            src={symbol}
                            alt="currency symbol"
                          />
                        </Box>
                        <H1>
                          {isClaimedReady ? (
                            toLowerCurrencyUnit(Number(getClaimable))
                          ) : (
                            <CircularProgress color="secondary" />
                          )}
                        </H1>
                      </Box>
                      <H5 style={{ fontWeight: "600", alignSelf: "flex-end" }}>
                        IRL
                      </H5>
                      {isButtonReady && isStringifiedReady ? (
                        isInitButton ? (
                          <InitVestingButton
                            onTxSuccess={handleInitButtonClick}
                            round={roundStrigified}
                          />
                        ) : isClaimButton && isClaimedReady ? (
                          <ClaimButton
                            onTxSuccess={handleClaimButtonClick}
                            roundNumber={round.roundNumber}
                          />
                        ) : (
                          <div>
                            <ButtonDropp
                              disabled={true}
                              buttonText="Nothing to claim"
                            />
                          </div>
                        )
                      ) : (
                        <CircularProgress color="secondary" />
                      )}
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          </VestingContentContainer>
        </Container>
      }
    </>
  );
};

export default ClaimRound;
