import styled from "styled-components";

interface IH4 {
  weight?: 500 | 600,
  noSpacing?: boolean,
}

 interface IH5 {
  weight?: 600 | 700,
  color?: 'primary' | 'secondary',
}

export const H1 = styled.h1`
  font-family: "Teko", sans-serif;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0px;
  margin: 0;
`;

export const H3 = styled.h3`
  font-family: "Teko", sans-serif;
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 28px;
  margin: 0;
`;

export const H4 = styled.h4<IH4>`
  font-family: "Poppins", sans-serif;
  font-weight: ${props => props.weight ? props.weight : 500};
  line-height: 24px;
  letter-spacing: ${props => props.noSpacing ? 0 : "2px"};
  text-align: left;
  margin: 0;
  margin-bottom: 0.25rem;
`;

export const H5 = styled.h5<IH5>`
  font-family: "Poppins", sans-serif;
  font-size: 0.875rem;
  font-weight: ${props => props.weight ? props.weight : 500};
  color: ${props => props.color ?
   props.color === 'secondary' ? "#1A82FF" : "#FFF"
  : "#000"};
  line-height: 21px;
  text-align: left;
  margin: 0;
  margin-bottom: 0.5rem;
`;

export const LabelH5 = styled(H5)`
    letter-spacing: 2px;
    margin-bottom: 0.25rem;
`;

export const ProgressH1 = styled(H1)`
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  line-height: 48px;
`