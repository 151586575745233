import LinearClaiming from "../claim/Claim";
import { useEffect, useState } from "react";
import { Container, VestingTopBar } from "../vesting/Vesting.styles";
import { Box, CircularProgress } from "@mui/material";
import logo from "../../assets/images/logo.svg";
import { useAccount } from "wagmi";
import { Web3Button } from "@web3modal/react";

const VestingFetch = () => {
  const [claim, setClaim] = useState<any>([]);
  const [irlPrice, setIrlPrice] = useState(0);
  const [isFetchingPrice, setIsFetchingPrice] = useState(true);
  const [isFetchingClaim, setIsFetchingClaim] = useState(true);
  const { address } = useAccount();

  useEffect(() => {
    const getIrlPrice = async () => {
      try {
        let response = await fetch(
          "https://us-central1-dropp-develop.cloudfunctions.net/droppPrice"
        );
        let droppData = await response.json();
        setIrlPrice(droppData.droppPrice);
        setIsFetchingPrice(false);
      } catch (e) {
        console.log("IRL Price fetching error: " + e);
        setIsFetchingPrice(false);
      }
    };
    getIrlPrice();
  }, []);

  useEffect(() => {
    const getClaim = async () => {
      try {
        if (address !== null) {
          let response = await fetch(
            `https://us-central1-dropp-develop.cloudfunctions.net/vestingData?userAddress=${address}`
          );
          let rounds = await response.json();
          let droppData = { rounds: rounds };
          setClaim(droppData);
        } else {
          setClaim([]);
        }
        setIsFetchingClaim(false);
      } catch (e) {
        console.log("Claim fetching error: " + e);
        setIsFetchingClaim(false);
      }
    };
    getClaim();
  }, [address]);

  if (isFetchingClaim || isFetchingPrice) {
    return (
      <Container>
        {/* <VestingTopBar>
          <Box>
            <img src={logo} alt="vesting logo" />
          </Box>
          <Web3Button />
        </VestingTopBar> */}
        <div style={{ position: "fixed", top: "50%", left: "50%" }}>
          <CircularProgress size={100} />
        </div>
      </Container>
    );
  }

  return <LinearClaiming claim={claim} irlPrice={irlPrice} />;
};

export default VestingFetch;
