import styled from "styled-components";

export const Container = styled.div`
  margin: 0;
  display: flex;
  flex-direction: column;
`;
export const VestingTopBar = styled.section`
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
`;

export const VestingContentContainer = styled.div`
  margin: 4rem auto auto;
  display: flex;
  flex-direction: column;
  //   min-width: 661px;
  min-height: 601px;
  gap: 25px;
`;
