import logo from "../../assets/images/logo.svg";
import {
  Container,
  VestingContentContainer,
  VestingTopBar,
} from "../vesting/Vesting.styles";
import { Box, CircularProgress, Grid } from "@mui/material";
import {
  ClaimProps,
  ParsedClaimProps,
  ParsedClaimRoundsProps,
} from "./claim.types";
import { Web3Button } from "@web3modal/react";

import ClaimRound from "../claimRound/ClaimRound";
import { H1, LabelH5 } from "../typefaces/typefaces.styles";
import { useEffect, useState } from "react";
import { toLowerCurrencyUnit } from "../../config/utils";
import Tab from "../tab/tab";
import "./Claim.css";
import { waitUntilCondition } from "../../utils/waitUntilCondition";

type Props = {
  claim: ClaimProps;
  irlPrice: number;
};

const cleanSomeVestingAdvisorsElementsStyles = () => {
  const elements = Array.from(
    document.querySelectorAll(
      `
      .MuiContainer-root .MuiGrid-container .MuiGrid-item div div:nth-of-type(2), 
      .MuiContainer-root .MuiGrid-container .MuiGrid-item div div:nth-of-type(3)
      `
    )
  ) as any[];

  elements.forEach((el) => {
    if (el?.style) el.style = "";
  });
};

const isVestingAdvisorsReady = async () => {
  return !!document.querySelectorAll(
    ".MuiContainer-root .MuiGrid-container .MuiGrid-item div div:nth-of-type(2)"
  );
};

const LinearClaiming = ({ claim, irlPrice }: Props) => {
  const [parsedClaim, setParsedClaim] = useState<any>();
  const [totalValueLocked, setTotalValueLocked] = useState(0);
  const [isParsedReady, setIsParsedReady] = useState(false);

  //check claim status
  useEffect(() => {
    const parseClaim = async () => {
      let parsedClaim: ParsedClaimProps = {
        rounds: claim.rounds.map((round) => ({
          roundNumber: round.roundNumber,
          startTime: round.startTime,
          endTime: round.endTime,
          data: {
            ...round.data,
            proofs: round.data.proofs.map((proof) => proof.proofs),
          },
        })),
      };

      // console.log("parsedClaim");
      // console.log(parsedClaim);

      setParsedClaim(parsedClaim);
      setIsParsedReady(true);

      // ugyly hack for remove automatic style attributes from generated elements by lib
      waitUntilCondition(
        isVestingAdvisorsReady,
        100,
        cleanSomeVestingAdvisorsElementsStyles
      );
    };

    parseClaim();
  }, [claim, irlPrice]);

  return (
    <>
      <div
        className="tabParetnt"
        style={{ marginTop: "100px", marginBottom: "100px" }}
      >
        <Container>
          {/* <VestingTopBar>
          <Box>
            <img src={logo} alt="vesting logo" />
          </Box>
          <Web3Button />
        </VestingTopBar> */}
          <VestingContentContainer>
            {isParsedReady ? (
              parsedClaim.rounds.length ? (
                <div className="tabContainer">
                  {parsedClaim.rounds.map((round: ParsedClaimRoundsProps) => (
                    <Tab key={round.roundNumber} number={round.roundNumber}>
                      <div className="tabContent">
                        <ClaimRound
                          key={round.roundNumber}
                          round={round}
                          irlPrice={irlPrice}
                        />
                      </div>
                    </Tab>
                  ))}
                </div>
              ) : (
                <H1>No vesting connected to this wallet address</H1>
              )
            ) : (
              <CircularProgress size={100} />
            )}
          </VestingContentContainer>
        </Container>
      </div>
    </>
  );
};

export default LinearClaiming;
