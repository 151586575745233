
export function toLowerCurrencyUnit(currency: number) {
  let orderOfMagnitude = Math.pow(10, 18);
  let m = Number((Math.abs(currency / orderOfMagnitude) * 100).toPrecision(15));

  return (Math.round(m) / 100 * Math.sign(currency / orderOfMagnitude));
}

export function formatTokenAmount(amount: bigint): string {
  const decimals = 18;
  const amountString = amount.toString();

  const integerPart = amountString.slice(0, -decimals) || '0';
  const fractionalPart = amountString.slice(-decimals);
  const trimmedFractionalPart = fractionalPart.replace(/0+$/, '');
  const formattedFractionalPart = trimmedFractionalPart.length > 0 ? `.${trimmedFractionalPart}` : '';

  return `${integerPart}${formattedFractionalPart}`;
}