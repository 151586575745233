import styled from "styled-components";

export const OverviewCardContainer = styled.div`
  box-shadow: none;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background-color: rgba(255, 255, 255, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 0.25rem;
  min-height: 130px;
  flex: 1;
`;
