import { CardContent, Grid, LinearProgress } from "@mui/material";
import { cardProgressStyle, progressBarContainer } from "../../../assets/styles/styles";
import { H3, H4, H5, ProgressH1 } from "../../typefaces/typefaces.styles";
import { InfoContainer } from "./VestingSchedule.styles";

type Props = {
  startTime: number;
  endTime: number;
};

const VestingSchedule = ({ startTime, endTime }: Props) => {

  const vestTotalDays = (endTime * 1000 - startTime * 1000) / (1000 * 3600 * 24);
  const vestElapsedDays = (new Date().getTime() - startTime * 1000) / (1000 * 3600 * 24);
  const vestProgress =
    100 < Math.round((vestElapsedDays / vestTotalDays) * 100)
      ? 100
      : Math.round((vestElapsedDays / vestTotalDays) * 100);

  const vestTotalMonths = Math.max(
    (new Date(endTime).getFullYear() - new Date(startTime).getFullYear()) * 12 +
      new Date(endTime).getMonth() -
      new Date(startTime).getMonth(),
    0
  );

  const isLockedStatus = vestProgress < 100 ? true : false;

  return (
    <div>
      <H4>Vesting Token Schedule</H4>
      <div style={progressBarContainer}>
        <LinearProgress
          sx={{ backgroundColor: "transparent" }}
          color="secondary"
          variant="determinate"
          value={vestProgress}
        />
      </div>

      <CardContent sx={cardProgressStyle}>
        <div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
          <InfoContainer>
            <H3>STATUS</H3>
            <H5 weight={600} color={"secondary"}>
              {isLockedStatus ? "LOCKED" : "OPEN"}
            </H5>
          </InfoContainer>
          <InfoContainer>
            <H3>VEST</H3>
            <H5 weight={600} color={"secondary"}>
              {vestTotalMonths} MTH
            </H5>
          </InfoContainer>
        </div>
        <div style={{ flexGrow: "1", paddingLeft: "60px", display: "flex", flexDirection: "row", gap: "40px" }}>
          <div style={{height: "88px", display: "flex", flexDirection: "column", justifyContent: "space-around"}}>
            <H5>Start Date</H5>
            <H4 weight={600} noSpacing>
              {new Intl.DateTimeFormat("pl-PL", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
              }).format(startTime * 1000)}
            </H4>
          </div>
          <div style={{height: "88px", display: "flex", flexDirection: "column", justifyContent: "space-around"}}>
            <H5>End Date</H5>
            <H4 weight={600} noSpacing>
              {new Intl.DateTimeFormat("pl-PL", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
              }).format(endTime * 1000)}
            </H4>
          </div>
        </div>
        <ProgressH1>{vestProgress}%</ProgressH1>
      </CardContent>
    </div>
  );
};

export default VestingSchedule;
