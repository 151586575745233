import { createTheme, CssBaseline, ThemeProvider } from "@mui/material";
import Content from "./components/content/Content";
import config from "./config/devnet.json";

import { createWeb3Modal, defaultWagmiConfig } from "@web3modal/wagmi/react";
import { WagmiConfig } from "wagmi";

import { bsc, mainnet, polygonMumbai } from "wagmi/chains";
import Layout from "./components/layout/layout";

const projectId = config.projectId;

const metadata = {
  name: "Web3Modal",
  description: "Web3Modal Example",
  url: "https://web3modal.com",
  icons: ["https://avatars.githubusercontent.com/u/37784886"],
};

const chains = [bsc, mainnet];
const wagmiConfig = defaultWagmiConfig({ chains, projectId, metadata });

createWeb3Modal({ wagmiConfig, projectId, chains });

function App() {
  const darkTheme = createTheme({
    palette: {
      mode: "dark",
      primary: {
        main: "rgba(255, 255, 255, 0.75)",
      },
    },
  });

  return (
    <Layout>
      <ThemeProvider theme={darkTheme}>
        <CssBaseline />
        <WagmiConfig config={wagmiConfig}>
          <Content />
        </WagmiConfig>
      </ThemeProvider>
    </Layout>
  );
}

export default App;
