import { useContractWrite, usePrepareContractWrite } from "wagmi";
import ButtonDropp from "../button/Button";
import ABI from "../../config/abi.json";
import { useEffect } from "react";

type Props = {
  roundNumber: number;
  onTxSuccess: () => void;
};

const ClaimButton = ({ roundNumber, onTxSuccess }: Props) => {
  
    const { config: claimConfig } = usePrepareContractWrite({
    address: "0x4151258Fb3D2a514b5cec91eF1D894023d79B6bd",
    abi: ABI,
    chainId: 56,
    functionName: "claim",
    args: [roundNumber],
  });

  const {
    error: claimError,
    write: claim,
    isLoading,
    isSuccess,
  } = useContractWrite(claimConfig);

  useEffect(() => {
    console.error(claimError);
  }, [claimError]);

  useEffect(() => {
    if (isSuccess) {
      onTxSuccess();
    }
  }, [isSuccess, onTxSuccess]);

  async function _claim() {
    claim?.();
  }

  return (
    <div onClick={() => _claim()}>
      <ButtonDropp
        isLoading={isLoading}
        disabled={isSuccess}
        buttonText="Claim IRL"
      />
    </div>
  );
};

export default ClaimButton;
