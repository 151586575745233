import { CircularProgress } from "@mui/material";
import { H1, H5 } from "../typefaces/typefaces.styles";
import { VestingDataProps } from "../vesting/vesting.types";
import { OverviewCardContainer } from "./OverviewCard.styles";

type Props = {
  data: VestingDataProps;
};

const OverviewCard = ({ data }: Props) => {

  return (
    <OverviewCardContainer>
      <div style={{margin: "8px 9px"}}>
        <H5>{data.title}</H5>
      </div>
      <div style={{margin: "21px 17px 0 0", display: "flex", justifyContent: "flex-end"}}>
        <H1>{data.value}</H1>
      </div>
      <div style={{margin: "8px 17px 24px 0", display: "flex", justifyContent: "flex-end", justifySelf: "flex-end", alignSelf: "flex-end"}}>
        <H5>{data.currency}</H5>
      </div>
    </OverviewCardContainer>
  );
};

export default OverviewCard;


// import { CircularProgress } from "@mui/material";
// import { H1, H3, H5, LabelH5 } from "../typefaces/typefaces.styles";
// import { VestingDataProps } from "../vesting/vesting.types";
// import { OverviewCardContainer } from "./OverviewCard.styles";

// type Props = {
//   data: VestingDataProps;
//   isReady: boolean;
// };

// const OverviewCard = ({ data, isReady }: Props) => {
//   return (
//     <OverviewCardContainer>
//       <div style={{ margin: "8px 9px" }}>
//         <H3>Round {data.round}</H3>
//       </div>
//       <div
//         style={{
//           margin: "8px 9px",
//           display: "flex",
//           flexDirection: "column",
//         }}
//       >
//         <LabelH5>Available to claim</LabelH5>
//         <H1>{data.value}</H1>
//         {/* {isReady ? <H1>{data.value}</H1> : <CircularProgress />} */}
//       </div>
//       {/* <div style={{margin: "8px 17px 24px 0", display: "flex", justifyContent: "flex-end", justifySelf: "flex-end", alignSelf: "flex-end"}}>
//         <H5>{data?.currency}</H5>
//       </div> */}
//     </OverviewCardContainer>
//   );
// };

// export default OverviewCard;
