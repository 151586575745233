import { Card, CardContent } from "@mui/material";
import { Box } from "@mui/system";
import logo from "../../assets/images/logo.svg";
import walletIcon from "../../assets/icons/Wallet.png";
import {
  headerStyle,
  cardContentStyle,
  cardStyle,
} from "../../assets/styles/styles";

const Connect = () => (
  <div>
    {/* <Box>
      <img src={logo} alt="vesting logo" />
    </Box> */}

    <Card style={cardStyle}>
      <CardContent sx={cardContentStyle}>
        <h3 style={headerStyle}>Vesting Module</h3>
        {/* <img
          src={walletIcon}
          style={{
            objectFit: "scale-down",
            width: "max-content",
            marginTop: "1.5rem",
            marginBottom: "4rem",
          }}
          alt="wallet icon"
        /> */}
        <w3m-button />
      </CardContent>
    </Card>
  </div>
);

export default Connect;
