type ConditionFunction = () => Promise<boolean>;
type CallbackFunction = () => void;

export const waitUntilCondition = (
  conditionFn: ConditionFunction,
  poolingIntervalInMilliseconds: number,
  callback: CallbackFunction
): (() => void) => {
  const intervalId = setInterval(async () => {
    const result = await conditionFn();
    if (result) {
      clearInterval(intervalId);
      callback();
    }
  }, poolingIntervalInMilliseconds);

  return () => clearInterval(intervalId);
};
