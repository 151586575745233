import React, { useEffect, useState } from "react";
import "./menu-nav.css";
import { MenuItemData } from "./menu-item/menu-item.model";
import MenuItem from "./menu-item/menu-item";
import { waitUntilCondition } from "../../../utils/waitUntilCondition";

const menuItems = [
  { label: "Home", link: process.env.REACT_APP_LINK_REBASE_GG + "/home" },
  //   {
  //     label: "About",
  //     submenu: [
  //       { label: "Programs", route: "/about" },
  //       { label: "App Details", route: "/app" },
  //       { label: "Partners", route: "/programs" },
  //     ],
  //   },
  //   {
  //     label: "Tokens",
  //   },
  //   // { label: 'Leaderboard', route: '/rebase-leaderboards' },
  //   {
  //     label: "News",
  //     route: "/news", // when uncomment submenu remove this line
  //     //   submenu: [
  //     //     { label: 'News', route: '/news', routePattern: '/news(/.+)?$' },
  //     //     { label: 'Tutorials' },
  //     //   ],
  //   },
] as MenuItemData[];

const MenuNav: React.FC = () => {
  const [selectedMenuItem, setSelectedMenuItem] = useState<MenuItemData | null>(
    null
  );

  const menuSelectionHandler = (item: MenuItemData) => {
    setSelectedMenuItem(item);
  };

  /**
   * ------------------------------------------------
   * This is ugly hack for color hex wallet string from gray into white
   * @returns
   */
  const getWalletHexFromDOM = () => {
    const w3mButtonList = document.querySelectorAll("w3m-button") || [];
    const wui_textArr = Array.from(w3mButtonList).map((w3mButton) => {
      let w3m_ab = w3mButton?.shadowRoot?.querySelector("w3m-account-button");
      let wui_ab = w3m_ab?.shadowRoot?.querySelector("wui-account-button");
      let wui_text = wui_ab?.shadowRoot?.querySelector(
        "button wui-flex wui-text"
      );
      return wui_text;
    });

    return wui_textArr;
  };

  const isWalletHexVisible = async () => {
    return getWalletHexFromDOM()?.length > 0;
  };

  useEffect(() => {
    waitUntilCondition(isWalletHexVisible, 100, () => {
      const hexs = getWalletHexFromDOM() as any[];

      hexs.forEach((hex) => {
        if (hex?.style) hex.style.color = "white";
      });
    });
  });

  // here hack ends;
  // ------------------------------------------------

  return (
    <nav className="menu_nav">
      {menuItems.map((item) => (
        <MenuItem
          key={item.label}
          item={item}
          onSelection={menuSelectionHandler}
          otherItemOpened={selectedMenuItem}
        />
      ))}

      <div className="menu__connectButton">{<w3m-button />}</div>
    </nav>
  );
};

export default MenuNav;
