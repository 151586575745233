import React from "react";
import "./socials.css";

const Socials: React.FC = () => {
  const links = {
    TWITTER: "https://twitter.com/REBASEgg",
    DISCORD: "https://discord.com/invite/rebasegg",
    TELEGRAM: "https://t.me/REBASE_gg",
    MEDIUM: "",
  };

  return (
    <main className="socials__main">
      <a href={links.TWITTER} target="_blank">
        <img
          src="/images/components/socials/twitter.svg"
          width="32"
          height="32"
          alt="twitter logo"
        />
      </a>
      <a href={links.DISCORD} target="_blank">
        <img
          src="/images/components/socials/discord.svg"
          width="32"
          height="32"
          alt="discord logo"
        />
      </a>
      <a href={links.TELEGRAM} target="_blank">
        <img
          src="/images/components/socials/telegram.svg"
          width="32"
          height="32"
          alt="telegram logo"
        />
      </a>
    </main>
  );
};

export default Socials;
