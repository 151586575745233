import { useAccount, useContractWrite, usePrepareContractWrite } from "wagmi";
import ButtonDropp from "../button/Button";
import ABI from "../../config/abi.json";
import { useEffect } from "react";
import { ParsedClaimRoundsProps } from "../claim/claim.types";

type Props = {
  round: ParsedClaimRoundsProps;
  onTxSuccess: () => void;
};

const InitVestingButton = ({ round, onTxSuccess }: Props) => {
    const { address } = useAccount();
  
    const { config: initVestingConfig } = usePrepareContractWrite({
    address: "0x4151258Fb3D2a514b5cec91eF1D894023d79B6bd",
    abi: ABI,
    chainId: 56,
    functionName: "initVestings",
    args: [
      round.roundNumber,
      address,
      round.data.indexes,
      round.data.amounts,
      round.data.proofs,
    ],
  });

  const {
    error: initError,
    write: initVesting,
    isLoading,
    isSuccess,
  } = useContractWrite(initVestingConfig);

  useEffect(() => {
    console.error(initError);
  }, [initError]);

  useEffect(() => {
    if (isSuccess) {
      onTxSuccess();
    }
  }, [isSuccess, onTxSuccess]);

  async function _initVesting() {
    initVesting?.();
  }

  return (
    <div onClick={() => _initVesting()}>
      <ButtonDropp
        isLoading={isLoading}
        disabled={isSuccess}
        buttonText="Initialize"
      />
    </div>
  );
};

export default InitVestingButton;
