import { Button, CircularProgress } from "@mui/material";

type Props = {
  buttonText: string;
  disabled?: boolean;
  isLoading?: boolean;
};

const ButtonDropp = ({ buttonText, disabled, isLoading }: Props) => {
  return (
    <Button
      disabled={disabled || isLoading}
      sx={{
        borderRadius: "12px",
        border: "0",
        height: "52px",
        width: "100%",
        fontSize: "1rem",
        cursor: "pointer",
        padding: "0px 16px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textTransform: "none",
        backgroundColor: "#7E87F7",
        color: "#F2F2F2",
        boxShadow: "0px 8px 24px rgba(126, 132, 255, 0.4)",
        borderBottom: "4px solid #6670EA",
        fontWeight: "700",
        transition: "background-color 200ms ease-out",
        "&:hover": {
          backgroundColor: "#6870E2",
          borderColor: "#525ADB",
        },
      }}
    >
      {isLoading ? (<CircularProgress color="primary" />) : ( buttonText )}
    </Button>
  );
};

export default ButtonDropp;
